<template>
    <div class="turnover">
        <!-- 会员流水 -->
        <div class="title">会员明细</div>
        <div class="card_box" v-if="showCard">
            <div class="card">
                <div class="top textColor">
                    <div>{{shopName}}</div>
                    <div class="top_r">VIP <span>NO.001</span></div>
                </div>
                <div class="center">
                    <a-input v-model="searchForm.keywords" @pressEnter="cardSearch" placeholder="请输入  手机号/卡号/姓名" />
                </div>
                <a-button class="btn OkBtn" @click="cardSearch">查询</a-button>
                
            </div>
        </div>
        <div class="tableBox" v-else>
            <div class="search searchForm">
                <div>
                    时间：
                    <a-range-picker :placeholder="['开始时间', '结束时间']" @change="handleChange" style="width:20vw;">
                        <a-icon slot="suffixIcon" type="calendar" />
                    </a-range-picker>
                </div>
                <div  style="fontSize:1.8vh">
                    支付方式：
                    <a-select style="width: 13vw;" v-model="searchForm.pay_type" placeholder="请选择">
                        <a-select-option :value="item.type_name" v-for="item in payList" :key="item.id">{{item.type_desc}}</a-select-option>
                    </a-select>
                </div>
                <div>
                    <a-button class="searchBtn" @click="searchHandle">筛选</a-button>
                </div>
                <div>
                    <a-button class="cancleBtn" @click="backHandle">返回</a-button>
                </div>
            </div>
            <div class="myTable">
                <a-table :data-source="tableData" :columns="columns" bordered 
                :rowKey="(record, index) => index" :pagination="pagination" @change="handleTableChange"  :locale="{emptyText: '暂无数据'}">
                </a-table>
            </div>
        </div>
        
    </div>
</template>
<script>
import {UserBill} from '@/request/mis'
import { payType } from '@/request/reportForm'
export default {
    name:'turnover',
    data(){
        return{
            siderName:'会员流水',
            shopName:'',
            showCard:true,
            searchForm:{
                listRows:10,
                page:1
            },
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'nick_name',
                    key: 'nick_name',

                },
                {
                    title: '手机号',
                    dataIndex: 'mobile',
                    key: 'mobile',
                },
                {
                    title: '操作人',
                    dataIndex: 'store_user_name',
                    key: 'store_user_name',
                },
                {
                    title: '时间',
                    dataIndex: 'pay_time',
                    key: 'pay_time',
                },
                {
                    title: '支付方式',
                    dataIndex: 'pay_type',
                    key: 'pay_type',
                },
                {
                    title: '交易进账',
                    dataIndex: 'pay_price',
                    key: 'pay_price',
                },
                {
                    title: '动作/备注',
                    dataIndex: 'remark',
                    key: 'remark',
                },
                {
                    title: '结果',
                    dataIndex: 'pay_status',
                    key: 'pay_status',
                }
            ],
            tableData:[],
            pagination: {
                current: 1,
                pageSize:10,
                total:0
            },
            payList:[]
        }
    },
    mounted(){
        this.shopName = JSON.parse(localStorage.getItem('shop_info')).shop_name
        payType().then(res => {
            console.log(res);
            if(res.data.status === 200){
                this.payList = res.data.data
            }
        })
        // this.keyUp()
    },
    methods:{
        // keyUp(){
        //     const _this=this;
        //     document.onkeydown = function(e) {
        //         let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
        //         if(e1 && e1.keyCode == 27){
        //         _this.$router.back();
        //         }
        //     }
        // },
        cardSearch(){
            this.searchHandle('showCard')
        },
        searchHandle(showCard){

            UserBill(this.searchForm).then(res => {
                
                if(res.data.status === 200){
                    this.pagination.total = res.data.data.total
                    this.tableData = res.data.data.list
                    showCard == 'showCard' ? this.showCard = false : ''
                }
            })
        },
        handleTableChange(e){
            this.searchForm.page = e.current
            this.pagination = e
            this.searchHandle()
        },
        handleChange(date,time){
            this.searchForm.start_time = time[0]
            this.searchForm.end_time = time[1]
        },
        backHandle(){
            this.showCard = true
            this.searchForm ={
                listRows:10,
                page:1
            }
        }
    },
}
</script>
<style lang="less" scoped>
.turnover{
    background: #f8f8ff;
    width: 100%;
    padding: 2vh;
    .title{
        margin: 1.5vh 0;
        font-size: 2vh;
        font-weight: bold;
        color: #656165;
    }
    .tableBox{
        .search{
            display: flex;
            align-items: center;
            margin-bottom: 2vh;
            >div{
                margin-right: 20px;
            }
            
        }
        .myTable{
            overflow: auto;
            height: 78vh;
        }
    }
    .card_box{
        background: rgba(0, 0, 0, 0);
        width: 83.1vw;
        height: 95.4vh;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 999;
        .card{
            width: 36vw;
            height: 20vw;
            background: #585252;
            border-radius: 2vh;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding: 2vw;
            .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 2vh;
                .top_r{
                    font-size: 1.5vh;
                    span{
                        color: #ffffff;
                    }
                }
            }
            .center{
                padding: 3.6vw 3.6vw 3vw 3.6vw;
                /deep/ .ant-input{
                    background-color: #585252!important;
                    color: #ffffff;
                    font-size: 2.2vh!important;
                    height: 5vh;
                }
                /deep/ .ant-input:hover{
                    border-color: #d9d9d9;
                }
                /deep/ .ant-input:focus{
                    border-color: #d9d9d9;
                }
            }
            .btn{
                border-radius: 1vh;
                position: absolute;
                left:50%;
                bottom: 1.5vh;
                transform: translateX(-50%);
            }
        }
    }
}

/deep/th,/deep/td{
    font-size: 1.8vh !important;
}

</style>